export const createCustomer =
`mutation customerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`

// Login
export const createCustomerAccessToken =
`mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`

// Logout
export const customerAccessTokenDelete = 
`mutation customerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
        deletedAccessToken
        deletedCustomerAccessTokenId
        userErrors {
            field
            message
        }
    }
}`

export const getCustomerDetails = 
`query($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
		id
        email
        firstName
        phone
        defaultAddress {
            firstName
            lastName
            address1
            city
            zip
            country
        }
        orders(first: 250) {
            nodes {
                name
                processedAt
                statusUrl
                currencyCode
				financialStatus
	            fulfillmentStatus
				currentSubtotalPrice {
				  amount
				  currencyCode
				}
				totalShippingPrice {
				  amount
				  currencyCode
				}
				shippingDiscountAllocations {
					allocatedAmount {
						amount
						currencyCode
					}
				}
	            subtotalPrice {
	              amount
	              currencyCode
	            }
				currentTotalTax {
  				  amount
  				  currencyCode
  				}
				totalRefunded {
				  amount
				  currencyCode
				}
				totalPrice {
				  amount
				  currencyCode
				}
                lineItems(first: 10) {
                    nodes {
					  title
	                  quantity
	                  discountedTotalPrice {
	                    amount
	                    currencyCode
	                  }
					  originalTotalPrice {
  	                    amount
  	                    currencyCode
  	                  }
					  discountAllocations {
						allocatedAmount {
							amount
							currencyCode	
						}
					  }
	                  variant {
	                    id
	                    image {
	                      originalSrc
	                      altText
	                    }
	                  }
                    }
                }
                shippingAddress {
					company
                    address1
					address2
                    city
                    lastName
                    firstName
                    zip
                    country
					phone
                }
				billingAddress {
					company
                    address1
					address2
                    city
                    lastName
                    firstName
                    zip
                    country
					phone
                }
            }
        }
    }
}`

export const updateCustomerPasswordMetafield = 
`mutation customerUpdate($input: CustomerInput!) {
    customerUpdate(input: $input) {
      customer {
        id
        metafield(namespace: "custom", key: "password") {
          value
        }
      }
      userErrors {
        field
        message
      }
    }
  }`
  
export const customerPasswordRecover =
`mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}`

export const customerResetPassword =
`mutation customerResetByUrl($password: String!, $resetUrl: URL!) {
    customerResetByUrl(password: $password, resetUrl: $resetUrl) {
      customer {
        id
        email
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
      userErrors {
        field
        message
      }
    }
}`

export const getCustomerSettings = 
`
query ($customerAccessToken: String!) {
  customer(customerAccessToken: $customerAccessToken) {
    id
    settings: metafield(namespace: "custom", key: "settings") {
      value
    }
  }
}
`

export const saveCustomerSettings =
`
mutation customerUpdate($input: CustomerInput!) {
  customerUpdate(input: $input) {
    customer {
      id
      metafield(namespace: "custom", key: "settings") {
        jsonValue
      }
    }
    userErrors {
      field
      message
    }
  }
}
`
